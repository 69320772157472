@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS for Google button */
.nsm7Bb-HzV7m-LgbsSe-BPrWId, .nsm7Bb-HzV7m-LgbsSe-MJoBVe, .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
    border-radius: 9% !important;
    width: 400px !important;
    overflow: hidden;
}
